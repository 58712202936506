import { nanoid } from 'nanoid';

import type {
  Analytics,
  AnalyticsEvent,
  GlobalEventData,
  InitialEventData,
} from '@Infrastructure/entities/analytics';
import type { Logger } from '@Infrastructure/entities/logger';
import type { CountryCode, CurrencyCode } from '@Types/Base';
import type { AppConfig } from '@Types/AppConfig';

interface Settings {
  analyticsUrl: string;
  logger: Logger;
  // TODO: Временный флаг; после перехода на Next.js удалить
  isNextJS?: boolean;
  environment?: AppConfig['environment'];
  proxyOrigin?: string;
  country?: CountryCode;
  allRegions?: string[];
  currency?: CurrencyCode;
  currencyRate?: number;
}

export class AnalyticsEventDispatcher implements Analytics {
  private eventsQueue: AnalyticsEvent[];

  private initialEventData: InitialEventData;

  dispatchEvent: Analytics['dispatchEvent'];

  private putEventToQueue = (name: AnalyticsEvent['name'], data?: AnalyticsEvent['data']) => {
    this.eventsQueue.push({ name, data });
  };

  private handleAnalyticsLoad = () => {
    this.dispatchEvent = (name, data) => {
      document.dispatchEvent(
        new CustomEvent<GlobalEventData>(name, {
          detail: { eventId: nanoid(8), initialEventData: this.initialEventData, ...data },
        }),
      );
    };

    this.eventsQueue.forEach((event) => this.dispatchEvent(event.name, event.data));
    this.eventsQueue = [];

    window.addEventListener('popstate', (e: PopStateEvent) => {
      const currentLocation = (e.target as Window).location;

      this.dispatchEvent('browser.navigate', {
        url: `${currentLocation.pathname}${currentLocation.search}`,
      });
    });

    window.addEventListener('beforeunload', () => {
      this.dispatchEvent('browser.unload', {
        url: `${document.location.pathname}${document.location.search}`,
      });
    });
  };

  constructor(settings: Settings) {
    this.eventsQueue = [];

    this.dispatchEvent = this.putEventToQueue;

    if (!settings.analyticsUrl) return;

    if (typeof window === 'undefined') return;

    if (settings.isNextJS) {
      if (
        ![
          settings.environment,
          settings.proxyOrigin,
          settings.country,
          settings.allRegions,
          settings.currency,
          settings.currencyRate,
        ].every(Boolean)
      )
        return;

      this.initialEventData = {
        country: settings.country,
        allRegions: settings.allRegions,
        currency: settings.currency,
        currencyRate: settings.currencyRate,
      };
    } else {
      const config = window.__CONFIG__;
      const serverState = window.__SERVER_STATE__;

      if (!config || !serverState) return;

      const { allRegions, country } = config;
      const { currency, currencyRate } =
        serverState.queries.find((query) => query.queryKey.includes('meta'))?.state.data || {};

      if (![allRegions, country, currency, currencyRate].every(Boolean)) return;

      this.initialEventData = { allRegions, country, currency, currencyRate };
    }

    try {
      const script = document.createElement('script');
      script.id = 'analytics-script';
      script.src = settings.analyticsUrl;
      script.crossOrigin = 'use-credentials';
      script.dataset.environment = window.__CONFIG__.environment;
      script.dataset.analyticsOrigin = window.__CONFIG__.analyticsOrigin;
      script.onload = this.handleAnalyticsLoad;
      document.body.appendChild(script);
    } catch (err) {
      settings.logger.log(`Error when load analytics: ${err}`);
    }
  }
}
