import { memo, lazy, useEffect } from 'react';
import { Outlet, ScrollRestoration } from 'react-router-dom';
import { DepsProvider } from '@divlab/divanui';

import * as Api from '@Api/index';
import * as ModalWindows from '@Stores/ModalWindows';
import * as HeaderStore from '@Stores/Header';
import { useDevPanel } from '@Stores/DevPanel';
import * as Meta from '@Queries/Meta';
import usePage from '@Queries/usePage';
import PageError from '@Pages/PageError';
import Page404 from '@Pages/Page404';
import ErrorBoundary from '@Components/ErrorBoundary';
import Suspense from '@Components/Suspense';
import FontCeraPro from '@Components/FontCeraPro';
import FontCloserText from '@Components/FontCloserText';
import MetaTags from '@Components/MetaTags';
import useRenderType from '@Hooks/useRenderType';
import { useRequest } from '@Contexts/Request';
import useMedias, { getMedias } from '@Hooks/useMedias';
import useSyncUrl from '@Hooks/useSyncUrl';
import { useDeps } from '@Contexts/DI';
import setCookie from '@Utils/setCookie';
import Link from '@Navigation/Link';
import useNavigation from '@Navigation/useNavigation';
import PopupManager from './elems/PopupManager';
import PageWrapper from './elems/PageWrapper';
import MediasDetector from './elems/MediasDetector';
import { YandexAB } from '@Components/YandexAB/YandexAB';

import '@UI/fonts.module.css';
import '../../../node_modules/@divlab/divanui/dist/index.css';

import type { FC } from 'react';

const LazyComponents = lazy(() => import('./LazyComponents'));

const ModalWindowsCleaner: FC = () => {
  const { isSSR } = useRenderType();

  // TODO: Приходится очищать вручную хранилища nanostores, иначе их состояние может быть общим для разных пользователей
  // Эту проблему стоит решить переписыванием хранилищ на react-query вместо nanostores, так как он гораздо предсказуемее во время серверного рендера
  if (isSSR) {
    ModalWindows.clear();
    HeaderStore.clear();
  }

  return null;
};

const Root: FC = () => {
  const page = usePage();

  const matches = useMedias();
  const { data: meta } = Meta.useMeta();
  const { pathname, search } = useNavigation();
  const { analytics, eventManager } = useDeps();
  const { currentDate: devCurrentDate } = useDevPanel();
  const currentDate = devCurrentDate || new Date();
  const { country, language } = useRequest();

  useSyncUrl();

  useEffect(() => {
    setCookie('sbjs_lTvd2', 'eyJpZCI6IjNmM2I2YmIzLTc0Y2QtNTkyOC1hMWIwLWQ3YTg0OT');
  }, []);

  useEffect(() => {
    const actualMatches = getMedias();

    analytics.dispatchEvent('browser.matchMedias', { matches: actualMatches });
  }, [analytics, matches]);

  useEffect(() => {
    analytics.dispatchEvent('page.open', {
      data: page.data,
      pathname,
      search,
      regionName: meta.region.name,
    });
    // Исключаем из зависимостей всё, кроме page.data, чтобы избежать дублирования событий
    // при несихронном изменении pathname и page.data, например
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [analytics, page.data]);

  return (
    <ErrorBoundary fallback={<PageError message={`Root error boundary`} />}>
      <FontCeraPro />
      <FontCloserText />
      <ModalWindowsCleaner />
      <MediasDetector />
      <ScrollRestoration
        getKey={(location) => {
          const paths = [/^\/cabinet/];

          return paths.some((path) => location.pathname.match(path))
            ? location.pathname
            : location.key;
        }}
      />

      <MetaTags pageMeta={page.data.meta} />

      <DepsProvider
        value={{
          components: { Link },
          currency: meta.currency,
          currentDate,
          country,
          language,
          analytics,
          eventManager,
        }}
      >
        <PopupManager />
        <PageWrapper>{page.data.page === 'NotFound' ? <Page404 /> : <Outlet />}</PageWrapper>
      </DepsProvider>

      <YandexAB />
      <Suspense fallback={null}>
        <LazyComponents />
      </Suspense>
    </ErrorBoundary>
  );
};

const Container: FC = () => {
  const request = useRequest();
  Api.setRequest(request);

  return (
    <ErrorBoundary fallback={<PageError message={`Container error boundary`} />}>
      <Root />
    </ErrorBoundary>
  );
};

export default memo(Container);
