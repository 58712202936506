import * as Api from '@Api/index';
import { BackendUnavailableError, IncorrectBackendResponseError } from '@Common/errors';
import { isAbsoluteLink } from '@Utils/isAbsoluteLink';
import { isNode } from '@Utils/checkRuntime';
import setCookie from '@Utils/setCookie';
import getCookie from '@Utils/getCookie';

import type { Query } from '@Types/Base';

const checkCookies = (request: Query) => {
  if (typeof document === 'undefined') return;
  const cookiesMap = { rgn: 'regionId', secondary_rgn: 'secondaryRegion' };
  Object.entries(cookiesMap).forEach(([key, value]) => {
    const cookieValue = getCookie(key);
    if (!cookieValue) {
      setCookie(key, request[value]);
    }
  });
};

async function query<T>(path: string, opts?: RequestInit) {
  const request = Api.getRequest();
  const proxyHost = typeof document === 'undefined' ? process.env.PROXY_ORIGIN_INTERNAL : '/proxy';
  const url = isAbsoluteLink(path) ? path : `${proxyHost}${path}`;
  const options = opts || {};
  const headers = options.headers || {};

  checkCookies(request);

  if (isNode) {
    Object.entries(request.headers).forEach(([key, value]) => {
      headers[key] = value;
    });
  } else {
    headers['X-Requested-With'] = 'XMLHttpRequest';
  }

  headers['X-Country'] = request.country;

  const params: RequestInit = {
    ...options,
    credentials: options.credentials || 'include',
    headers,
  };

  const res = await fetch(url, params);

  // Отдельный тип ошибки для случая, когда бэкенд недоступен
  if (res.status >= 500) {
    throw new BackendUnavailableError();
  }

  // Пытаемся распарсить ответ от бэкенда как JSON
  let json: T = null;
  try {
    json = await res.json();
  } catch (err) {
    throw new IncorrectBackendResponseError({ url, status: res.status });
  }

  return json;
}

export default query;
