import { useQuery } from '@tanstack/react-query';

import ApiSite from '@Api/Site';
import useSearchParams from '@Hooks/useSearchParams';
import generateQueryKeys from '@Queries/helpers/generateQueryKeys';
import { useRequest } from '@Contexts/Request';
import useNavigation from '@Navigation/useNavigation';

import type { UseQueryResult } from '@tanstack/react-query';
import type { BasePage } from '@Types/Base';

function usePage<T>(queryKeys: string[] = []): UseQueryResult<T & BasePage> {
  const { queryClient, region } = useRequest();
  const location = useNavigation();

  const searchParams = useSearchParams();

  const url = `${location.pathname}?${searchParams}`;
  const keys = generateQueryKeys({
    keys: ['page', region, ...queryKeys],
    pathname: location.pathname,
    search: location.search,
  });
  const placeholder: BasePage = {
    meta: {
      tags: [],
    },
    page: '',
  };

  const query = useQuery({
    queryKey: keys,
    queryFn: () => {
      const cache = queryClient.getQueryData<T & BasePage>(keys);
      if (cache) return Promise.resolve(cache);

      return ApiSite.fetchPage<T & BasePage>({ url });
    },
    enabled: true,
    placeholderData: (prev) => prev || (placeholder as any),
  });

  return query;
}

export default usePage;
