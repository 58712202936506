import { type FC, type HTMLAttributes } from 'react';

import Link from '@UI/Link';
import HttpStatus from '@Navigation/HttpStatus';
import styles from './ErrorInfo.module.css';

import type { HttpStatusCode } from '@Contexts/Request';

export interface ErrorInfoProps extends HTMLAttributes<HTMLDivElement> {
  className?: string;
  code?: HttpStatusCode;
}

interface TextInfo {
  title: string;
  description: string;
  link: string;
}

// NOTE: Здесь специально используется текст на дефолтном языке стран,
// так как в случае ошибки мы не можем гарантировать работоспособность механизма локализации
// и будет надежнее иметь именно статичную страницу без зависимости от запросов
const defaultTextInfo: TextInfo = {
  title: 'Ой! Что-то пошло не так...',
  description: 'Не переживайте, вы можете обновить страницу или вернуться на главную.',
  link: 'Перейти на главную',
};

export const ErrorInfo: FC<ErrorInfoProps> = (props) => {
  const { code, ...restProps } = props;

  return (
    <HttpStatus code={code || 500}>
      <div {...restProps}>
        <div className={styles.header} />
        <div className={styles.wrapper}>
          <div className={styles.wrapperIcon}>
            <div className={styles.iconFurniture} />
          </div>
          <div className={styles.contentWrapper}>
            <h1 className={styles.heading}>{defaultTextInfo.title}</h1>
            <div className={styles.text}>{defaultTextInfo.description}</div>
            <Link className={styles.link} to='/' view='primary' underlined>
              {defaultTextInfo.link}
            </Link>
          </div>
        </div>
        <div className={styles.footer} />
      </div>
    </HttpStatus>
  );
};

export default ErrorInfo;
