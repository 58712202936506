import * as Api from '@Api/index';

function createProxyUrl(path: string): string {
  const {
    language,
    region,
    targetRegion,
    allRegions,
    supportedLanguages,
    mainRegion,
    defaultLanguage,
  } = Api.getRequest();
  const [pathname, search = ''] = path.split('?');

  let chunks = pathname.split('/');
  const finalRegion = targetRegion || region;

  // Формируем ссылку в которой мы гарантируем корректность выбранного языка и региона
  if (chunks[1] === 'backend') {
    // Удаляем из адреса регион/язык
    const filteredChunks = chunks.filter((chunk) => {
      return ![...allRegions, ...supportedLanguages, 'backend'].includes(chunk);
    });

    // Собираем заново в строго определенном порядке
    const regionSlug = finalRegion === mainRegion ? '' : finalRegion;
    const lang = language.slug === defaultLanguage ? '' : language.slug;
    chunks = ['', 'backend', lang, regionSlug, ...filteredChunks]
      .map((chunk) => (chunk || '').replace(/\//g, ''))
      .filter((chunk, index) => index === 0 || chunk !== '');
  }

  // Добавляем слэш после 'backend', иначе не пройдём по маске прокси
  if (chunks[chunks.length - 1] === finalRegion) chunks.push('');

  const fullUrl = `${chunks.join('/')}${search ? `?${search}` : ''}`;

  return fullUrl;
}

export default createProxyUrl;
