export type CookieOptions = {
  'max-age': string | number;
  domain: string;
  expires: Date | string;
};

export function createCookieDomain() {
  const { hostname } = window.location;
  const root = hostname.replace(/^www\./, '');
  return `.${root}`;
}

function setCookie(name: string, value: string, options: Partial<CookieOptions> = {}) {
  const assigmentOptions = {
    path: '/',
    domain: options.domain || createCookieDomain(),
    ...options,
  };

  if (assigmentOptions.expires instanceof Date) {
    assigmentOptions.expires = assigmentOptions.expires.toUTCString();
  }

  let updatedCookie = `${encodeURIComponent(name)}=${encodeURIComponent(value)}`;

  Object.keys(assigmentOptions).forEach((key) => {
    const optionValue = assigmentOptions[key];

    if (!!optionValue) {
      updatedCookie += `; ${key}`;

      if (optionValue !== true) {
        updatedCookie += `=${optionValue}`;
      }
    }
  });

  document.cookie = updatedCookie;
}

export default setCookie;
